import React, { useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared";
import { useQuery, useDelete } from "../../../hooks";
import { useGetDisplayConfigsQuery, useDeleteDisplayConfigMutation } from "../services";
import { DisplayConfigurationsForm } from "./Form";
import { getLocalizedString } from "../../../shared/translation";
import { DetailsDropDownView, TableDropDownView } from "../../ManagementPortal/Shared";
import { TickOrCross } from "../../Shared";

export const DisplayConfigurationsContainer = () => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_description_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "display_configurations.created_at",
    order_dir: "desc",
  };

  const headers = [
    { label: "Display Name", key: "name", className: "display_name" },
    { label: "Map", key: "map", className: "map" },
    {
      label: getLocalizedString("map_key", "API Key"),
      type: "component",
      className: "api-key more",
      component: ({ data }) => {
        return <DetailsDropDownView data={data?.map_key} />;
      },
    },
    {
      label: "Dark Mode",
      nestedValue: true,
      getNestedValue: ({ dark_mode }) => {
        return <TickOrCross flag={dark_mode} />;
      },
      className: "enabled",
    },
    {
      label: "Variables",
      type: "component",
      className: "applications date more",
      component: ({ data }) => {
        return <>{JSON.stringify(data?.variable_ids)}</>;
      },
    },
  ];

  /* API Calls */
  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetDisplayConfigsQuery({
    ...queryParams,
    q,
  });

  const {
    deleteItem: deleteDisplayConfiguration,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteDisplayConfigMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  /* Api Calls End */

  /* Actions */
  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  return (
    <>
      <>
        <article className="main-container">
          <AdminSharedHeader
            createNewHandler={createNewHandler}
            groupName="other"
            heading="Configurations"
            handleSearchKey={setSimpleSearch}
            simpleSearchPlaceHolder="Search by Name"
          />
          <AdminSharedTable
            isLoading={isFetching}
            error={error}
            isSuccess={isSuccess}
            headers={headers}
            data={data?.data}
            pagination={{ ...pagination, count: data.total_count }}
            onDelete={deleteClickHandler}
            onEdit={editClickHandler}
            className="display"
            auditResource="Display"
            auditKey="name"
          />
        </article>

        {showForm && (
          <DisplayConfigurationsForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />
        )}

        <AdminSharedDeleteModal
          show={showDeleteWarning}
          resourceName="Display"
          error={deleteErrorMsg}
          getDeleteItemName={() => {}}
          onHide={() => {
            setDeleteErrorMsg();
            setShowDeleteWarning(false);
          }}
          onDelete={() => deleteDisplayConfiguration({ id: idToDelete })}
        />
      </>
    </>
  );
};
