import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { displayConfigFormSchema } from "../validations";
import * as Components from "../../../components";
import { useForm, useDropDownSearch } from "../../../hooks";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateDisplayConfigMutation,
  useGetDisplayConfigQuery,
  useGetVariablesQuery,
  useUpdateDisplayConfigMutation,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { getLocalizedString } from "../../../shared/translation";
import { mapProviders } from "../../../shared/dropdownLists";

export const DisplayConfigurationsForm = ({ idToEdit = null, closeForm }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    map: "",
    map_key: "",
    dark_mode: false,
    variable_ids: [],
  });
  const [headers, setHeaders] = useState([
    {
      label: "Variable",
      key: "",
      error: "",
      dataType: "",
      className: "variable",
    },
    {
      label: "Display Precision",
      key: "",
      error: "",
      dataType: "",
      className: "precision",
    },
    {
      className: "action",
    },
  ]);
  const [mappingValues, setmappingValues] = useState({
    id: "",
    precision: "",
  });
  const [displayNameMap, setDisplayNameMap] = useState({});
  const [reorderedMappingData, setReorderedMappingData] = useState([]);
  const mapColumnNames = ["id"];

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (value) => handleFormSubmit(value),
    validationSchema: displayConfigFormSchema,
  });

  const formikVariableMapping = useFormik({
    initialValues: mappingValues,
    enableReinitialize: true,
  });

  /* API Calls */

  const { data: selectedDisplayConfig } = useGetDisplayConfigQuery(
    { id: idToEdit },
    { skip: !idToEdit }
  );

  // Get data for target variables list
  const { data: variables = { data: [] } } = useGetVariablesQuery({
    per_page: 2000,
    order_by: "variables.display_name",
    classification_type: ["tracking_data_device", "tracking_data_custom"],
  });

  const { create: createDisplayConfiguration, update: updateDisplayConfiguration } = useForm({
    createMutation: useCreateDisplayConfigMutation,
    updateMutation: useUpdateDisplayConfigMutation,
    closeForm: closeForm,
    setError: formik.setErrors,
  });

  /* End API Calls */

  useEffect(() => {
    if (variables) {
      let tempObj = {};
      variables.data.forEach((data) => {
        tempObj[data.id] = data.display_name;
      });
      setDisplayNameMap({ id: tempObj });
    }
  }, [variables]);

  useEffect(() => {
    if ((idToEdit, selectedDisplayConfig)) {
      const mapConfig = { qiMap: 0, mapbox: 1, google: 2 };

      const updatedValue = {
        ...selectedDisplayConfig,
        map: mapConfig[selectedDisplayConfig?.map],
      };
      setInitialValues(mergeObjects(initialValues, updatedValue));

      //Map Variabke Ids
      const tempVariablesData = selectedDisplayConfig?.variable_ids?.map((item) => {
        return {
          id: item.id,
          precision: item.precision,
        };
      });
      setReorderedMappingData(tempVariablesData);
    }
  }, [idToEdit, selectedDisplayConfig]);

  /* QI Table Custom Actions */

  const handleSubmitVariables = (value) => {
    formik.setFieldValue("variable_ids", value);
  };

  const handleOnCancel = () => {};

  const handleChange = (name, value) => {
    const parsedValue = value === "" ? "" : JSON?.parse(value);

    if (name === "id") {
      formikVariableMapping.setFieldValue(name, parsedValue?.id);
    } else {
      formikVariableMapping.setFieldValue(name, value);
    }
  };

  /* QI Custom Table Action End */

  /* Form Actions */

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateDisplayConfiguration({ id: idToEdit, formData: formData });
    } else {
      createDisplayConfiguration({ formData: formData });
    }
  };

  return (
    <>
      <FormContainer
        resourceName="Display Configuration"
        closeForm={closeForm}
        idToEdit={idToEdit}
        handleFormSubmit={formik.handleSubmit}
      >
        <Components.QIInput
          label={"Display Name"}
          type="text"
          {...formik.getFieldProps("name")}
          error={formik.touched.name && formik.errors.name}
        />
        <Components.QICustomSelect
          label={getLocalizedString("map", "Map")}
          {...formik.getFieldProps("map")}
          value={formik.values.map}
          onChange={(value) => formik.setFieldValue("map", value)}
          error={formik.touched.map && formik.errors.map}
        >
          {mapProviders.map((value, index) => (
            <li value={value?.id} key={value?.id}>
              {value?.name}
            </li>
          ))}
        </Components.QICustomSelect>
        <Components.QIInput
          label={getLocalizedString("map_key", "Map Key")}
          {...formik.getFieldProps("map_key")}
          onChangeEdit={(value) => {
            formik.setFieldValue("map_key", value);
          }}
          type="password"
          edit={idToEdit}
          disabled={idToEdit}
          autoComplete="off"
          error={formik.touched.map_key && formik.errors.map_key}
          //handleWarningMessage={(value) => setResetPasswordWarning(value)}
          //warningMessage={`${resetPasswordWarning ? "Please tick to reset password." : ""}`}
        />
        <Components.QISwitch
          label={getLocalizedString("dark-mode", "Dark Mode")}
          {...formik.getFieldProps("dark_mode")}
          onChange={() => formik.setFieldValue("dark_mode", !formik.values.dark_mode)}
        />
        <Components.QITableCustom
          handleSubmit={handleSubmitVariables}
          headers={headers}
          objectItem={formikVariableMapping.values}
          validationRequired={false}
          handleOnCancel={handleOnCancel}
          initialValues={reorderedMappingData}
          displayNameMap={displayNameMap}
          mapColumnNames={mapColumnNames}
        >
          <div className="flex items-center gap-8">
            <div className="qi-select_wrapper">
              <select
                className="qi-select_text"
                onChange={(e) => handleChange("id", e.target.value)}
              >
                <>
                  <option value="">select</option>
                  {variables?.data?.map((data, index) => (
                    <option value={JSON.stringify(data)} key={index}>
                      {data?.display_name}
                    </option>
                  ))}
                </>
              </select>
              <div className="text-red-600 small-error">{headers[0]?.error}</div>
            </div>
            <div className="qi-select_wrapper">
              <input
                className="qi-input"
                onChange={(e) => handleChange("precision", e.target.value)}
                type="Number"
              />
              <div className="text-red-600 small-error">{headers[1]?.error}</div>
            </div>
          </div>
        </Components.QITableCustom>
      </FormContainer>
    </>
  );
};
